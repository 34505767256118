<template>
  <div class="stepper" :class="{'stepper2': theme == 'black'}">
    <div class="stepper-title">
      <div>EDISON</div>
      <div>Development Plan</div>
    </div>
    <q-stepper
      v-model="step"
      ref="stepper"
      animated
      flat
      header-nav
      active-icon="language"
      :vertical="isVertical()"
    >
      <q-step
        :name="1"
        title="2021"
        icon="language"
        :done="step > 1"
      >
        <div class="step-label">Q1</div>
        <div>In February, the Al large model database was built and trained;</div>
        <div class="step-label">Q4</div>
        <div>In November, an encrypted operation team was set up to lay out the encryption track;</div>
      </q-step>

      <q-step
        :name="2"
        title="2022"
        icon="language"
        :done="step > 2"
      >
        <div class="step-label">Q1</div>
        <div>In March, the implementation of artificial intelligence AI quantitative investment began;</div>
        <div class="step-label">Q2</div>
        <div>In June, financial assets managed by artificial intelligence AI showed explosive growth;</div>
        <div class="step-label">Q3</div>
        <div>In September, it began to invest in high-quality crypto trading platforms and DEFI projects;</div>
      </q-step>

      <q-step
        :name="3"
        title="2023"
        icon="language"
        :done="step > 3"
      >
      <div class="step-label">Q1</div>
      <div>In February, Two Sigma invests in the Edison Al Foundation;</div>
      <div class="step-label">Q3</div>
      <div>In August, the Foundation's Edison AI Wealth Hub platform was launched;</div>
      <div class="step-label">Q4</div>
      <div>In October, the Edison AI aggregation robot was launched;</div>
      <div>In December, the Edison AI DEFI section was launched;</div>
      </q-step>

      <q-step
        :name="4"
        title="2024"
        icon="language"
        :done="step > 4"
      >
        <div class="step-label">Q2</div>
        <div>In April, the EdisonAI public chain wallet was launched;</div>
        <div class="step-label">Q3</div>
        <div>In August, Edison AI encrypted social was launched;</div>
      </q-step>

      <q-step
        :name="5"
        title="2025"
        icon="language"
      >
        <div class="step-label">Q2</div>
        <div>In June, the EdisonAl public chain ecological application was launched;</div>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: '',
  components: {},
  props: {
    isXs: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: "white"
    }
  },
  setup() {
    return {
      step: ref(1)
    }
  },
  methods: {
    isVertical() {
      if(this.isXs) {
        return true
      }
      if(this.$store.state.mode == 'md') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.stepper {
  margin-top: 200px;
  padding: 0 110px;
}

.stepper-title {
  margin-bottom: 50px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.stepper :deep(.q-stepper) {
  background-color: transparent;
}

.stepper :deep(.q-stepper__title) {
  color: #fff;
  font-size: 30px;
}

.stepper :deep(.q-stepper__step-content) {
  color: #fff;
  font-size: 15px;
}

.stepper :deep(.q-stepper__tab--active) {
  color: #55A856;
}

.stepper :deep(.q-stepper__tab--done) {
  color: #55A856;
}

.step-label {
  margin-top: 15px;
  font-weight: bold;
  color: #ffde89;
}

.stepper2 :deep(.q-stepper__title) {
  color: #000;
}

.stepper2 :deep(.q-stepper__step-content) {
  color: #000 !important;
}

.stepper2 .step-label {
  margin-top: 15px;
  font-weight: bold;
  color: #6EADFD;
}


@media screen and (max-width: 1000px) {
  .stepper {
    margin-top: 30px;
    padding: 0 20px;
  }

  .stepper-title {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .stepper :deep(.q-stepper__title) {
    color: #fff;
    font-size: 18px;
  }

  .stepper2 :deep(.q-stepper__title) {
    color: #000;
  }

  .stepper2 :deep(.q-stepper__step-content) {
    color: #000 !important;
  }

}
</style>