<template>
  <div class="official" ref="official">
    <div class="header">
      <img src="@/assets/img/logo2.png" style="width: 180px;margin: 0;" />
      <van-popover v-model:show="showPopover" :actions="actions" placement="bottom-end" @select="onSelect" v-if="mode == 'xs'">
        <template #reference>
          <q-icon name="menu" size="40px" style="color: #fff;"></q-icon>
        </template>
      </van-popover>
      <div class="menu" v-if="mode == 'md'">
        <div class="menu-item" v-for="(item,index) in actions" :key="index" @click="onSelect(item)">
          {{ item.text }}
        </div>  
      </div>
    </div>

    <div class="main-text">
      <h1>
        <div>Edison AI Wealth Hub</div>
        <div class="special">arbitrage quantitative combination</div>
        <div class="special2">is an AI quantitative investment management platform based on the integration of artificial intelligence and blockchain technology</div>
      </h1>
    </div>

    <div class="row">
      <div class="main-buttons-icons">
        <h5>support swap</h5>
        <div class="icons-row">
          <img src="@/assets/img/base/icons1.png" @click="openLink('https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x55d398326f99059fF775485246999027B3197955')" />
          <img src="@/assets/img/base/icons2.png" @click="openLink('https://quickswap.exchange/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&swapIndex=0')" />
          <img src="@/assets/img/base/icons3.png" @click="openLink('https://app.uniswap.org/#/swap')" />
          <img src="@/assets/img/base/icons4.png" @click="openLink('https://www.gate.io/zh/trade/BTC_USDT')" />
        </div>
      </div>
    </div>

    <div class="gateway">
      <div class="gateway-title">
        <div>It is invested and operated by the Edison Foundation of the United Kingdom, and has received a huge donation from the world's top quantitative giant Two Sigma.</div>
      </div>
      <div class="gateway-wrap wow animate__fadeInUp" data-wow-duration="2s">
        <img src="@/assets/img/base/graph-desktop.png" class="desktop-img" />
        <div class="gateway-text">
          Support from BNB Smart Chain
          Quantitative ecology is applicable to Pancakeswap, BNBswap, DYDX, Uniswap, DODO, Biswap, etc.
        </div>
      </div>
    </div>

    <div class="main-products wow animate__fadeInRight" data-wow-duration="2s">
      <div class="main-products-title">Our advantages</div>
      <div class="main-products-list">
        <div class="main-products-item">
          <img src="@/assets/img/base/icon-1.png" />
          <div class="main-products-item-title">Innovation</div>
          <div class="main-products-item-label">
            The latest open AI conducts data mining algorithm analysis and works out grid strategies through Generative Pre-Training to achieve stable quantitative returns
          </div>
        </div>
        <div class="main-products-item">
          <img src="@/assets/img/base/icon-2.png" />
          <div class="main-products-item-title">Safety</div>
          <div class="main-products-item-label">
            A number of investment institutions favor, by the financial regulator certification, quantitative technology of the latest stable molding benefits
          
          </div>
        </div>
        <div class="main-products-item">
          <img src="@/assets/img/base/icon-3.png" />
          <div class="main-products-item-title">Robust</div>
          <div class="main-products-item-label">
            Committed to building the world's leading "datastrategic trading" trinity digital asset financial ecological environment. Build a big data storage and analysis platform.
          </div>
        </div>
      </div>
    </div>

    <div class="container-skew wow animate__fadeIn" data-wow-duration="3s">
      <div class="token-in">
        <div class="token-info">
          <div class="token-info-title">EDISON Token</div>
          <div class="token-info-lable">   
            The Edison AI Foundation issued the token CCAI, with a total issuance of 21 million tokens at an issue price of 0.01U, a total deflation of 99%, and finally 210,000 tokens in circulation, the ultimate destruction of unlimited applications，In the various defects in CPMM, CSMM, CMMM, Edison AI is through complex mathematical equations, unique liquidity pool design, the main pool only arbitrage CCAI does not buy the control mechanism, the secondary pool only returns USDT to the main pool of the active LP plug-in mechanism, effectively solve the problem of DEFI malicious stock coins and inability to control the disk. Price oracles, flash exchanges, and various other technical improvements were introduced.
          </div>
        </div>
        <div class="token-body">
          <img src="@/assets/img/base/bitcoinist.jpg" />
        </div>
      </div>
    </div>

    <official-scroll></official-scroll>

    <official-stepper></official-stepper>

    <div class="main-media wow animate__fadeInLeft" data-wow-duration="2s">
      <div class="main-media-title">In the media</div>
      <div class="main-media-list">
        <div class="main-media-item">
          <img src="@/assets/img/base/1f.jpg" />
          <div class="main-media-item-title">WTTE</div>
          <div class="main-media-item-label">WTTE is a television station in Columbus, Ohio, United States, airing programming from the digital multicast network TBD.</div>
        </div>
        <div class="main-media-item">
          <img src="@/assets/img/base/2z.jpg" />
          <div class="main-media-item-title">Benzinga</div>
          <div class="main-media-item-label">Benzinga is a Detroit, Michigan-based financial news website that provides investment advice and analysis, as well as aggregated content from other sites such as TheStreet.com and WSJ.com</div>
        </div>
        <div class="main-media-item">
          <img src="@/assets/img/base/3n.png" />
          <div class="main-media-item-title">NewsNet</div>
          <div class="main-media-item-label">NewsNet (stylized as NEWSnet) is an American news-oriented free-to-air television network and newscast production company owned by Bridge News</div>
        </div>
      </div>
    </div>

    <div class="getintouch wow animate__fadeInUp" data-wow-duration="2s">
      <div class="getintouch-logo">
        <img src="@/assets/img/base/AlphaGPT.png" />
      </div>
      <div class="getintouchins">
        <div class="getintouch-title">Get in touch</div>
        <div class="getintouch-text">Have any question? Send us a message through these channels.</div>
        <div class="getintouch-list">
          <div class="getintouch-item" @click="openLink(parameter.telegram)">
            <div class="getintouch-img-wrap">
              <img src="@/assets/img/base/telegram.png" />
            </div>
            <div class="getintouch-item-label">Telegram</div>
          </div>
          <div class="getintouch-item" @click="openLink(parameter.twitter)">
            <div class="getintouch-img-wrap">
              <img src="@/assets/img/base/email.svg" />
            </div>
            <div class="getintouch-item-label">E-mail</div>
          </div>
          <div class="getintouch-item">
            <a :href="'mailto:' + parameter.mailbox" style="color: #000;">
              <div class="getintouch-img-wrap">
                <img src="@/assets/img/base/twitter.png" />
              </div>
              <div class="getintouch-item-label">twitter</div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">Copyright @2023 EDISON AI. All Right Reserved</div>
    
    <!-- <particles></particles> -->
  </div>
</template>

<script>
// import Particles from '@/components/particles/Index.vue'
import { Popover } from 'vant';
import { ref } from 'vue';
import 'animate.css';
import { WOW } from 'wowjs';

import OfficialScroll from './components/OfficialScroll.vue'
import OfficialStepper from './components/OfficialStepper.vue'

export default {
  name: '',
  components: {
    [Popover.name]: Popover,
    OfficialScroll,
    OfficialStepper,
    // Particles
  },
  setup() {
    return {
      mode: ref("md"),
      showPopover: ref(false),
      actions: [
				{text: "White Paper", lx: 2, val: "https://edison-6.gitbook.io/edison-ai-white-paper/"},
				{text: "Management Regulations", lx: 1,  val: 2},
				{text: "Model Charter", lx: 1,  val: 12},
				{text: "License", lx: 1,  val: 11},
				{text: "Privacy Policy", lx: 1,  val: 9},
				{text: "About Us", lx: 1,  val: 1},
			],
      parameter: ref({})
    }
  },
  created() {
    this.getSetting()
  },
  mounted() {
    this.$nextTick(() => {
      new WOW({live: false}).init();
    });
    let _this = this
    let clientWidth = this.$refs.official.offsetWidth
    _this.mode = clientWidth <= 768 ? "xs" : "md"
    _this.$store.commit("setMode", _this.mode);

    window.onresize = function() {
      _this.$nextTick(() => {
        let clientWidth = _this.$refs.official.offsetWidth
        _this.mode = clientWidth <= 768 ? "xs" : "md"
        _this.$store.commit("setMode", _this.mode);
      })
    } 
  },
  methods: {
    openLink(url) {
      window.open(url)
    },
    onSelect(e) {
			if(e.lx == 1) {
				this.$router.push("Help?id=" + e.val)
			}
			if(e.lx == 2) {
				window.open(e.val)
			}
			if(e.lx == 3) {
				this.$q.dialog({
					message: "Coming soon",
				});
			}
		},
    getSetting() {
				let _this = this
				_this.$request.post(
					"api/SystemSetting/GetSetting",
					{
						// token: _this.$utils.getloc("token"),
						// 	userid: _this.us.userid,
					},
					(res) => {
						if(res.data.code == 0) {
							_this.$q.dialog({
								message: res.data.msg,
							});
							return
						}
						let data = res.data.data
						_this.parameter = data
					}
				)
			}
  }
}
</script>

<style scoped>
.official {
  background: url(~@/assets/img/base/official.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.header {
	padding: 16px 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.menu {
  display: flex;
  color: #fff;
}

.menu-item {
  font-size: 16px;
  font-weight: bold;
  padding: 0 15px;
  cursor: pointer;
}

.main-text {
  padding: 0 40px;
  margin: 0 0 90px 0;
  text-align: center;
}

.main-text h1 {
  font-size: 64px;
  color: #fff;
}

.main-text .special {
  /* -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(135deg,#D75B74 30%,#A000CC 100%); */
  color: #fff;
}

.main-text .special2 {
  /* -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(135deg,#D75B74 30%,#A000CC 100%); */
  color: #fff;
}

.main-buttons-icons {
  margin: 0 auto 90px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
}

.main-buttons-icons h5 {
  margin-right: 32px;
  font-size: 20px;
}

.icons-row {
  display: flex;
  align-items: center;
}

.icons-row img {
  margin: 0 10px;
  width: 52px;
  height: 52px;
  cursor: pointer;
}

.gateway-title {
  padding: 0 40px;
  /* -webkit-text-fill-color: transparent; */
  /* -webkit-background-clip: text; */
  font-size: 52px;
  font-weight: bold;
  text-align: center;
  /* background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%); */
  color: #fff;
}

.gateway-wrap {
  padding: 0 30px;
}

.desktop-img {
  width: 100%;
}

.gateway-text {
  width: 100%;
  max-width: 400px;
  margin: -6% 0 0 80px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.main-products {
  padding: 0 110px;
}

.main-products-title {
  margin-top: 50px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.main-products-list {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.main-products-item {
  width: 32%;
  padding: 32px 32px 100px 32px;
  border-radius: 24px;
  background-color: #fff;
}

.main-products-item img {
  width: 48px;
  height: 48px;
}

.main-products-item-title {
  font-size: 32px;
  font-weight: bold;
}

.main-products-item-label {
  margin-top: 6px;
  font-size: 16px;
}

.container-skew {
  margin: 200px 0;
  padding: 0 110px;
}

.token-in {
  padding: 56px 46px;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: #fff;
}

.token-info , .token-body{
  flex: 1;
}

.token-info {
  padding-right: 20px;
  text-align: center;
}

.token-info-title {
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 64px;
  font-weight: bold;
}

.token-info-lable {
  font-size: 24px;
  font-weight: bold;
  color: gray;
}

.token-body img {
  width: 100%;
}

.token-footer-title {
  margin-top: 15px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 24px;
  font-weight: bold;
}

.main-partners-title {
  padding: 0 110px;
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.main-media {
  margin-top: 150px;
  padding: 0 110px;
}

.main-media-title {
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
}

.main-media-list {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}

.main-media-item {
  width: 32%;
}

.main-media-item img {
  width: 100%;
  border-radius: 30px;
}

.main-media-item-title {
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}

.main-media-item-label {
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.getintouch {
  margin-top: 200px;
  padding: 0 110px;
}

.getintouch-logo {
  text-align: center;
}

.getintouch-logo img {
  width: 175px;
}

.getintouchins {
  position: relative;
  margin-top: -70px;
  padding: 85px;
  border-radius: 30px;
  background-color: #fff;
  z-index: 2;
}

.getintouch-title {
  background-image: linear-gradient(135deg,#ffde89 0,#79ffe7 50%,#caa0ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 52px;
  font-weight: bold;
  text-align: center;
}

.getintouch-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.getintouch-list {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.getintouch-item {
  margin: 0 20px;
  text-align: center;
}

.getintouch-img-wrap {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.getintouch-item:nth-child(1) .getintouch-img-wrap {
  background-color: #0b3470;
}

.getintouch-item:nth-child(2) .getintouch-img-wrap {
  background-color: #554D3C;
}

.getintouch-item:nth-child(3) .getintouch-img-wrap {
  background-color: #484972;
}

.getintouch-img-wrap img {
  width: 44px;
}

.getintouch-item-label {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.footer {
  margin-top: 100px;
  padding: 40px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, .3);
}
 
@media screen and (max-width: 768px) {
  .main-text {
    padding: 0 20px;
    margin: 0 0 30px 0;
  }

  .main-text h1 {
    font-size: 32px;
    line-height: 48px;
  }

  .gateway-title {
    padding: 0 20px;
    font-size: 32px;
  }

  .gateway-wrap {
    padding: 0 20px;
  }

  .gateway-text {
    font-size: 16px;
    margin: 0 0 0 20px
  }
  
  .main-products {
    padding: 0 20px;
  }

  .main-products-title {
    font-size: 32px;
  }

  .main-products-list {
    flex-wrap: wrap;
  }

  .main-products-item {
    margin-bottom: 20px;
    padding: 32px;
    width: 100%;
  }

  .container-skew {
    margin: 30px 0;
    padding: 0 20px;
  }

  .token-in {
    padding: 20px;
    flex-wrap: wrap;
  }

  .token-info {
    flex: none;
    width: 100%;
  }

  .token-body {
    margin-top: 10px;
    flex: none;
    width: 100%;
  }

  .token-info-title {
    font-size: 32px;
  }

  .token-info-lable {
    font-size: 16px;
  }

  .token-footer-title {
    font-size: 16px;
  }

  .main-media {
    margin-top: 30px;
    padding: 0 20px;
  }

  .main-media-title {
    font-size: 32px;
  }

  .main-media-list {
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .main-media-item {
    width: 100%;
    margin-bottom: 30px;
  }

  .main-media-item-title {
    font-size: 16px;
  }

  .main-media-item-label {
    font-size: 14px;
  }

  .getintouch {
    margin-top: 30px;
    padding: 0 20px;
  }

  .getintouchins {
    padding: 20px;
  }

  .getintouch-title {
    font-size: 32px;
  }

  .getintouch-text {
    font-size: 16px;
  }

  .getintouch-list {
    margin-top: 30px;
  }

  .getintouch-img-wrap {
    width: 60px;
    height: 60px;
    border-radius: 16px;
  }

  .getintouch-img-wrap img {
    width: 30px;
    height: 30px;
    border-radius: 16px;
  }

  .getintouch-item-label {
    font-size: 16px;
  }

  .footer {
    margin-top: 40px;
    padding: 20px 0;
    font-size: 13px;
    font-weight: normal;
  }

  .main-buttons-icons {
    margin:  0 auto 30px;
  }

  .main-buttons-icons {
    flex-direction: column;
  }

  .main-buttons-icons h5 {
    padding: 10px 0 15px;
    margin: 0;
  }

  .icons-row {
    padding-bottom: 10px;
  }

  .icons-row img {
    margin: 0 15px;
    width: 35px;
    height: 35px;
  }
}
</style>